// src/App.js
import React, { Suspense, useContext, lazy } from 'react';
import { RouterProvider } from "react-router/dom";
import { createBrowserRouter, Outlet, Navigate } from 'react-router';
import { AuthProvider, useAuth } from './context/AuthContext';



// Public Route Wrapper
const PublicRoute = () => {
  const { user } = useAuth();
  return !user ? <Outlet /> : <Navigate to="/" replace />;
};


const router = createBrowserRouter([
  {
    lazy: () => import("./pages/protected/ProtectedRoute"),
    
    children: [
      {
        path: '/',
        lazy: () => import("./pages/protected/init"),
      },
      {
        path: '/:bID',
        lazy: () => import("./pages/protected/business/root"),
       
        children: [
          { index: true, lazy: () => import("./pages/Dashboard") },
          { path: 'dashboard', lazy: () => import("./pages/Dashboard") },
          { path: 'customer', Component: () => (<h2>Customer</h2>) },
          { path: 'transaction', Component: () => (<h2>Transaction</h2>) },
          { path: 'profile', Component: () => (<h2>Profile</h2>) },
        ]
      },
      {
        path: 'onboarding',
        lazy: () => import("./pages/protected/onboarding/root"),
        children: [
          { index: true, lazy: () => import("./pages/protected/onboarding/contact"), },
          { index: true, path: 'contact', lazy: () => import("./pages/protected/onboarding/contact"), },
          { path: 'business', lazy: () => import("./pages/protected/onboarding/business"), },
          { path: 'profile', Component: () => (<h2>Profile</h2>) },
        ]
      },
      {
        path: 'my',
        lazy: () => import("./pages/protected/my/root"),
        children: [
          { path: 'profile', Component: () => (<h2>Profile</h2>) },
          { path: 'business', lazy: () => import("./pages/protected/my/business") },
          { path: 'settings', element: (<h2>Settings</h2>) },
        ]
      },
    ],
  }, {
    path: "/",
    lazy: () => import("./pages/public/PublicRoute"),
    children: [
      { path: "/SignIn", lazy: () => import("./pages/sign-in") },
      { path: "/SignUp", lazy: () => import("./pages/sign-up") },
    ]
  }
], {
  future: {
    v7_relativeSplatPath: true,
    v7_startTransition: true,
  },
});

const App = () => {

  return (
    <AuthProvider>
      <Suspense fallback={<><div>Loading(Suspence in App.js)...</div><Outlet /></>}>
        <RouterProvider router={router} />
      </Suspense>
    </AuthProvider>
  );
};

export default App;
