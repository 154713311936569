// src/services/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyACTQ7UDNeegr6fb3sGlsEMwi4jjP66QVI",
  authDomain: "incentiv-4333b.firebaseapp.com",
  projectId: "incentiv-4333b",
  storageBucket: "incentiv-4333b.firebasestorage.app",
  messagingSenderId: "294051129021",
  appId: "1:294051129021:web:416649601e26372448b9d4",
  measurementId: "G-3TCP9JWJ7T"
};


const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);
