// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../services/firebase';
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const myLinks = [
    { to: '/my/profile', name: 'Profile' },
    { to: '/my/business', name: 'Business' },
    { to: '/my/review', name: 'Review' },
    { to: '/my/favorites', name: 'Favorites' },
    { to: '/my/setting', name: 'Setting' },
    { to: '/my/help-support', name: 'Help & Support' }
  ]
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <AuthContext.Provider value={{ user, myLinks }}>{children}</AuthContext.Provider>
  );
}
